import { FuseNavigationItem } from "@fuse/components/navigation";

export default function SalesAdvisorNav() {
  const nav: FuseNavigationItem[] = [
    {
      id: "crm",
      title: "CRM",
      subtitle: "Interactua con los clientes",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "dashboard-v2",
          title: "Dashboard",
          type: "basic",
          icon: "heroicons_outline:chart-pie",
          link: "/dashboard",
        },
        {
          id: "crm.clientes",
          title: "Clientes",
          type: "basic",
          icon: "heroicons_outline:users",
          link: "/crm/customers",
        },
        {
          id: "usersProspects",
          title: "Leads",
          type: "basic",
          icon: "heroicons_outline:user",
          link: "/crm/prospects",
        },
        {
          id: "crm.opportunity",
          title: "Oportunidades",
          type: "basic",
          icon: "heroicons_outline:shopping-cart",
          link: "/crm/opportunity",
        },
        {
          id: "crm.payment_order",
          title: "Planes de pagos",
          type: "basic",
          icon: "heroicons_outline:receipt-percent",
          link: "/crm/payment-orders",
        },
        {
          id: "crm.payments-of-week",
          title: "Ingresos del mes",
          type: "basic",
          icon: "heroicons_outline:cash",
          link: "/crm/payments-of-week",
        },
        {
          id: "activities",
          title: "Calendario de Actividades",
          type: "basic",
          icon: "heroicons_outline:calendar",
          link: "/crm/activities",
        },
        {
          id: "customersWallet",
          title: "Asesores de venta",
          type: "basic",
          icon: "heroicons_outline:briefcase",
          link: "/crm/salesAdvisors",
        },
        {
          id: "payments-request",
          title: "Confirmación de pagos",
          type: "basic",
          icon: "heroicons_outline:wallet",
          link: "/crm/payments-request",
        },
        {
          id: "cession-of-right",
          title: "Cesiones de derecho",
          type: "basic",
          icon: "heroicons_outline:list-bullet",
          link: "/crm/cession-of-right",
        },
        {
          id: "change-of-product",
          title: "Cambios de inmueble",
          type: "basic",
          icon: "heroicons_outline:switch-horizontal",
          link: "/crm/change-of-product",
        },
        {
          id: "withdrawal-requests",
          title: "Solicitudes de retiros",
          type: "basic",
          icon: "heroicons_outline:x-circle",
          link: "/crm/withdrawal-requests",
        },
        {
          id: "reports",
          title: "Reportes",
          type: "collapsable",
          icon: "heroicons_outline:folder-open",
          children: [
            {
              id: "report.old_balances",
              title: "Antiguedad de saldos",
              type: "basic",
              link: "/crm/report/old-balances",
            },
            {
              id: "report.customer_payment_status",
              title: "Estado de cuenta de clientes",
              type: "basic",
              link: "/crm/report/customers-payment-status",
            },
            {
              id: "report.customer_payment_group",
              title: "Estado de cuenta agrupado",
              type: "basic",
              link: "/crm/report/customers-payment-group",
            },
            {
              id: "report.pending_with_drawal",
              title: "Devoluciones pendientes",
              type: "basic",
              link: "/crm/report/pending-with-drawal",
            },
            {
              id: "report.customers_advisor",
              title: "Clientes por asesores",
              type: "basic",
              link: "/crm/report/customers-advisor",
            },
            {
              id: "report.advisor_payment_status",
              title: "Estado de Cuenta de Asesores",
              type: "basic",
              link: "/crm/report/advisor-payment-status",
            },
            {
              id: "report.top_sold",
              title: "Inmuebles vendidos",
              type: "basic",
              link: "/crm/report/top-sold",
            },
            {
              id: "report.opportunity_origins",
              title: "Orígenes de oportunidades",
              type: "basic",
              link: "/crm/report/opportunity-origins",
            },
            {
              id: "report.master",
              title: "Oportunidades",
              type: "basic",
              link: "/crm/report/master",
            },
            {
              id: "report.master-leads",
              title: "Leads",
              type: "basic",
              link: "/crm/report/master-leads",
            },
            {
              id: "report.master-clients",
              title: "Clientes",
              type: "basic",
              link: "/crm/report/master-client",
            },
            {
              id: "report.master-products",
              title: "Inmuebles",
              type: "basic",
              link: "/crm/report/master-product",
            },
            {
              id: "report.available-products",
              title: "Inmuebles disponibles",
              type: "basic",
              link: "/crm/report/available-products",
            },
            {
              id: "report.payments-history",
              title: "Historial de pagos",
              type: "basic",
              link: "/crm/report/payments-history",
            },
            {
              id: "report.payments-reserve-history",
              title: "Pagos de reserva",
              type: "basic",
              link: "/crm/report/payments-reserve-history",
            },
            {
              id: "report.customer-info-report",
              title: "Información de clientes activos",
              type: "basic",
              link: "/crm/report/customer-info-report",
            },
            {
              id: "report.documentation-compliance",
              title: "Cumplimiento de documentación",
              type: "basic",
              link: "/crm/report/documentation-compliance",
            },
            {
              id: "report.paid-commission-per-month",
              title: "Pagos de comisiones",
              type: "basic",
              link: "/crm/report/paid-commission-per-month",
            },
            {
              id: "report.pending-paid-commissions",
              title: "Pagos de comisiones pendientes",
              type: "basic",
              link: "/crm/report/pending-paid-commissions",
            },
          ],
        },
      ],
    },
    {
      id: "inventory",
      title: "Inventario",
      subtitle: "Administra los inmuebles y proveedores",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "inventory.products",
          title: "Inmuebles",
          type: "basic",
          icon: "heroicons_outline:shopping-bag",
          link: "/inventory/products",
        },
      ],
    },
  ];

  return nav;
}
