import { FuseNavigationItem } from "@fuse/components/navigation";

export default function PracticingNav() {
  const nav: FuseNavigationItem[] = [
    {
      id: "crm",
      title: "CRM",
      subtitle: "Interactua con los clientes",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "dashboard-v2",
          title: "Dashboard",
          type: "basic",
          icon: "heroicons_outline:chart-pie",
          link: "/dashboard",
        },
        {
          id: "crm.payment_order",
          title: "Planes de pagos",
          type: "basic",
          icon: "heroicons_outline:receipt-percent",
          link: "/crm/payment-orders",
        },
        {
          id: "reports",
          title: "Reportes",
          type: "collapsable",
          icon: "heroicons_outline:folder-open",
          children: [
            {
              id: "report.top_sold",
              title: "Inmuebles vendidos",
              type: "basic",
              link: "/crm/report/top-sold",
            },
            {
              id: "report.master-products",
              title: "Inmuebles",
              type: "basic",
              link: "/crm/report/master-product",
            },
            {
              id: "report.available-products",
              title: "Inmuebles disponibles",
              type: "basic",
              link: "/crm/report/available-products",
            },
            {
              id: "report.paid-commission-per-month",
              title: "Pagos de comisiones",
              type: "basic",
              link: "/crm/report/paid-commission-per-month",
            },
            {
              id: "report.documentation-compliance",
              title: "Cumplimiento de documentación",
              type: "basic",
              link: "/crm/report/documentation-compliance",
            },
            {
              id: "report.pending-paid-commissions",
              title: "Pagos de comisiones pendientes",
              type: "basic",
              link: "/crm/report/pending-paid-commissions",
            },
            {
              id: "report.commission-per-project",
              title: "Comisiones por proyecto",
              type: "basic",
              link: "/crm/report/commissions-per-project",
            },
            {
              id: "report.commissions-available-for-payment",
              title: "Comisiones disponibles para pago",
              type: "basic",
              link: "/crm/report/commissions-available-for-payment",
            },
          ],
        },
      ],
    },
  ];

  return nav;
}
